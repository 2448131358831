import { Add, MoreVert } from "@mui/icons-material";
import {
  Box,
  Button,
  IconButton,
  LinearProgress,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
} from "@mui/material";
import { Session } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import CompanyLogo from "./CompanyLogo";
import ProductDialog from "./ProductDialog";

export default ({
  session,
  backendApi,
  activeOrgId,
  activeCompanyId,
}: {
  session: Session;
  backendApi: BackendApi;
  activeOrgId: string;
  activeCompanyId: string;
}) => {
  const [page, setPage] = useState(0);
  const [perPage, setPerPage] = useState(25);
  const [total, setTotal] = useState(0);
  const [companyProducts, setCompanyProducts] = useState([]);
  const [loading, setLoading] = useState(false);
  const [dialogOpen, setDialogOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>(null);

  const getProducts = async (page) => {
    if (!session || !session.user || !activeOrgId || !activeCompanyId) return;
    setLoading(true);
    const { data, count } = await backendApi.getManagedProducts(
      session.user.id,
      activeCompanyId,
      activeOrgId,
      page,
      perPage
    );
    setCompanyProducts(data);
    setTotal(count);
    setLoading(false);
  };

  useEffect(() => {
    getProducts(page);
  }, [activeOrgId, activeCompanyId, page, perPage]);

  return (
    <Box>
      <ProductDialog
        open={dialogOpen}
        initialData={selectedProduct}
        handleClose={() => {
          setDialogOpen(false);
          getProducts(page);
        }}
        session={session}
        backendApi={backendApi}
        activeOrgId={activeOrgId}
        activeCompanyId={activeCompanyId}
      />
      <Box sx={{ display: "flex", justifyContent: "space-between", m: 3 }}>
        <Typography variant="h6">Products</Typography>
        <Button
          variant="outlined"
          startIcon={<Add />}
          onClick={() => {
            setSelectedProduct(null);
            setDialogOpen(true);
          }}
        >
          Create Product
        </Button>
      </Box>
      <LinearProgress sx={{ display: loading ? "block" : "none", mt: 1 }} />
      <TableContainer>
        <Table aria-label="product table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Image</TableCell>
              <TableCell>Categories</TableCell>
              <TableCell>Rating</TableCell>
              <TableCell>Reviews</TableCell>
              <TableCell></TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {companyProducts.map((product) => (
              <TableRow key={product.name}>
                <TableCell>{product.name}</TableCell>
                <TableCell>
                  <CompanyLogo product={product} sx={{}} />
                </TableCell>
                <TableCell>
                  {product.categories.map((x) => x.name).join(",  ") ||
                    "General"}
                </TableCell>
                <TableCell>{product.rating || "N/A"}</TableCell>
                <TableCell>{product.reviews}</TableCell>
                <TableCell>
                  <IconButton
                    onClick={() => {
                      setSelectedProduct(product);
                      setDialogOpen(true);
                    }}
                  >
                    <MoreVert />
                  </IconButton>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <TablePagination
        component="div"
        count={total}
        page={page}
        rowsPerPage={perPage}
        onRowsPerPageChange={(e: any) =>
          setPerPage(parseInt(e.target.value, 10))
        }
        onPageChange={(e: any, v: number) => setPage(v)}
      />
    </Box>
  );
};
