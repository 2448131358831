import {
  Avatar,
  Box,
  Button,
  FormControlLabel,
  FormGroup,
  IconButton,
  Switch,
  TextField,
} from "@mui/material";
import { Session } from "@supabase/supabase-js";
import axios from "axios";
import { useSnackbar } from "notistack";
import React, { useEffect, useRef, useState } from "react";
import BackendApi from "../BackendApi";
import LoadingButton from "../components/LoadingButton";
import { API_HOSTNAME } from "../constants";

export default ({
  session,
  backendApi,
  activeOrgId,
}: {
  session: Session;
  backendApi: BackendApi;
  activeOrgId: string;
}) => {
  const fileInputRef = useRef(null);
  const [open, setOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [formData, setFormData] = useState({
    isPublic: false,
    name:
      session && session.user.user_metadata
        ? session.user.user_metadata.name
        : "",
    picture: "",
    keywords: "",
    description: "",
    projectDetails: "",
  });

  useEffect(() => {
    setFormData({
      isPublic: false,
      name:
        session && session.user.user_metadata
          ? session.user.user_metadata.name
          : "",
      picture:
        session && session.user.user_metadata
          ? session.user.user_metadata.avatar_url
          : "",
      keywords: "",
      description: "",
      projectDetails: "",
    });
  }, [session]);

  const handleChange = (e, field, index) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const convertToBase64 = (file): Promise<string> => {
    return new Promise<string>((resolve, reject) => {
      const fileReader = new FileReader();
      fileReader.readAsDataURL(file);

      fileReader.onload = () => {
        resolve(fileReader.result as string);
      };

      fileReader.onerror = (error) => {
        reject(error);
      };
    });
  };

  const handleFileSelect = async (event) => {
    try {
      const file = event.target.files[0];

      // Basic validation
      if (!file.type.startsWith("image/")) {
        alert("Please select an image file");
        return;
      }

      // Size validation (e.g., 5MB limit)
      if (file.size > 5 * 1024 * 1024) {
        alert("File size should be less than 5MB");
        return;
      }

      const base64String: string = await convertToBase64(file);
      setFormData({ ...formData, picture: base64String });
    } catch (error) {
      console.error("Error converting image:", error);
      alert("Error processing image. Please try again.");
    }
  };

  const handleUpdatePhoto = () => {
    fileInputRef.current.click();
  };

  const getProfile = () => {
    axios
      .get(`${API_HOSTNAME}/consultants/profile`, {
        headers: { Authorization: `Bearer ${session.access_token}` },
      })
      .then((res) => {
        if (res.data.consultant) {
          setFormData(res.data.consultant);
        }
      });
  };

  const handleSave = () => {
    setLoading(true);
    axios
      .post(`${API_HOSTNAME}/consultants`, formData, {
        headers: { Authorization: `Bearer ${session.access_token}` },
      })
      .catch((err) => {
        enqueueSnackbar("Error saving profile");
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    if (session && session.access_token) {
      getProfile();
    }
  }, [session]);

  return (
    <Box sx={{ maxWidth: 600, p: 3 }}>
      <IconButton onClick={handleUpdatePhoto}>
        <Avatar
          sx={{ height: 80, width: 80 }}
          alt={formData.name}
          src={formData.picture}
        />
      </IconButton>
      <input
        type="file"
        ref={fileInputRef}
        style={{ display: "none" }}
        accept="image/*"
        onChange={handleFileSelect}
      />
      <Button sx={{ mt: 3 }} variant="outlined" onClick={handleUpdatePhoto}>
        Update Photo
      </Button>
      <FormGroup sx={{ mt: 2 }}>
        <FormControlLabel
          control={
            <Switch
              checked={formData.isPublic}
              onChange={(e) =>
                setFormData({ ...formData, isPublic: e.target.checked })
              }
            />
          }
          label="Public Profile"
        />
      </FormGroup>
      <TextField
        autoFocus
        margin="normal"
        id="name"
        label="Name"
        type="text"
        fullWidth
        value={formData.name}
        onChange={(e) => handleChange(e, "name", null)}
      />
      <TextField
        margin="normal"
        id="keywords"
        label="Keywords"
        type="text"
        fullWidth
        value={formData.keywords}
        onChange={(e) => handleChange(e, "keywords", null)}
      />
      <TextField
        id="description"
        label="Description"
        type="text"
        fullWidth
        rows={4}
        multiline
        margin="normal"
        value={formData.description}
        onChange={(e) => handleChange(e, "description", null)}
      />
      <TextField
        id="projectDetails"
        label="Project Details"
        type="text"
        fullWidth
        rows={4}
        multiline
        margin="normal"
        value={formData.projectDetails}
        onChange={(e) => handleChange(e, "projectDetails", null)}
      />
      <LoadingButton
        loading={loading}
        sx={{ mt: 2 }}
        onClick={handleSave}
        variant="outlined"
        color="primary"
      >
        Save
      </LoadingButton>
    </Box>
  );
};
