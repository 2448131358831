import { Search } from "@mui/icons-material";
import {
  Box,
  debounce,
  InputAdornment,
  LinearProgress,
  List,
  ListItem,
  ListItemButton,
  Pagination,
  TextField,
  useTheme,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import ConfirmationDialog from "../components/ConfirmationDialog";
import { PRODUCT_NAME } from "../constants";
import ProductDetailDialog from "./ProductDetailDialog";
import ProductRow from "./ProductRow";

const ProductsPage = ({
  mode,
  supabase,
  session,
  activeOrgId,
  backendApi,
}: {
  mode: string;
  supabase: SupabaseClient;
  session: Session;
  activeOrgId: string;
  backendApi: BackendApi;
}) => {
  const [menuItem, setMenuItem] = useState("products");
  const [claimOpen, setClaimOpen] = useState(false);
  const [claimedProduct, setClaimedProduct] = useState<any>({});
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [productDetailOpen, setProductDetailOpen] = useState(false);
  const [menuOpen, setMenuOpen] = useState(true);
  const theme = useTheme();

  const handleClaimClick = (product: any) => {
    setClaimedProduct(product);
    setClaimOpen(true);
  };

  const openProductDetail = (product: any) => {
    setSelectedProduct(product);
    setProductDetailOpen(true);
  };

  const onConfirmClaim = async () => {
    setClaimOpen(true);
    await backendApi.createClaimRequest(
      activeOrgId,
      session.user.id,
      claimedProduct.id
    );
    setClaimedProduct({});
  };

  return (
    <Box
      sx={{
        display: "flex",
        flexDirection: "row",
      }}
    >
      <ConfirmationDialog
        open={claimOpen}
        onClose={() => setClaimOpen(false)}
        onConfirm={onConfirmClaim}
        title={`Claim Product ${claimedProduct.name}?`}
        message={`Next steps:

- A ${PRODUCT_NAME} representative will be reaching out to complete the claim process.
- You will be granted admin status for your profile which will enable you to update your information.
- Connect to real users who are researching you and get the most value out of ${PRODUCT_NAME}!
        `}
        confirmText={"confirm"}
        cancelText={"cancel"}
      />
      <ProductDetailDialog
        open={productDetailOpen}
        product={selectedProduct}
        onClose={() => setProductDetailOpen(false)}
        backendApi={backendApi}
        activeOrgId={activeOrgId}
        session={session}
      />
      <Box
        sx={{
          transition: theme.transitions.create(["margin", "width"], {
            easing: theme.transitions.easing.sharp,
            duration: theme.transitions.duration.leavingScreen,
          }),
          width: 0,
          borderRight:
            theme.palette.mode === "dark"
              ? "1px solid rgba(255, 255, 255, 0.12)"
              : "1px solid rgba(0, 0, 0, 0.12)",
          visibility: "hidden",
          whiteSpace: "nowrap",
          ...(menuOpen && {
            width: 250,
            visibility: "visible",
            transition: theme.transitions.create(
              ["margin", "width", "visibility"],
              {
                easing: theme.transitions.easing.easeOut,
                duration: theme.transitions.duration.enteringScreen,
              }
            ),
          }),
        }}
      >
        <Box sx={{ p: 1 }}>
          <List>
            <ListItemButton
              disabled={menuItem === "products"}
              selected={menuItem === "products"}
              onClick={() => setMenuItem("products")}
            >
              Products
            </ListItemButton>
            <ListItemButton
              disabled={menuItem === "catalog"}
              selected={menuItem === "catalog"}
              onClick={() => setMenuItem("catalog")}
            >
              Catalog
            </ListItemButton>
          </List>
        </Box>
      </Box>
      <Box sx={{ height: "calc(100vh - 67px)", width: "100%", p: 1 }}>
        <Box sx={{ display: menuItem === "products" ? "block" : "none" }}>
          <ProductsTable
            backendApi={backendApi}
            session={session}
            handleClaimClick={handleClaimClick}
            activeOrgId={activeOrgId}
            openProductDetail={openProductDetail}
          />
        </Box>
        <Box sx={{ display: menuItem === "catalog" ? "block" : "none" }}>
          <CatalogTable
            backendApi={backendApi}
            session={session}
            handleClaimClick={handleClaimClick}
            activeOrgId={activeOrgId}
            openProductDetail={openProductDetail}
          />
        </Box>
      </Box>
    </Box>
  );
};

const ProductsTable = ({
  session,
  backendApi,
  handleClaimClick,
  activeOrgId,
  openProductDetail,
}: {
  session: Session;
  backendApi: BackendApi;
  handleClaimClick: (product: any) => void;
  activeOrgId: string;
  openProductDetail: (product: any) => void;
}) => {
  const [pages, setPages] = useState(1);
  const [products, setProducts] = useState([]);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(false);

  const getProducts = async (page: number) => {
    setLoading(true);
    const { data, count } = await backendApi.getMyProducts(
      session.user.id,
      page,
      limit
    );
    setLoading(false);
    setProducts(data);
    setPages(Math.ceil(count / limit));
  };

  const pageChange = (e, v) => {
    getProducts(v - 1);
  };

  useEffect(() => {
    getProducts(0);
  }, [activeOrgId]);

  return (
    <Box>
      <LinearProgress sx={{ display: loading ? "block" : "none", mt: 1 }} />
      <List>
        {products.length === 0 && !loading && (
          <ListItem>No products added to canvas</ListItem>
        )}
        {products.map((product, index) => (
          <ProductRow
            product={product}
            key={index}
            onClick={() => openProductDetail(product)}
            sx={{}}
          />
        ))}
      </List>
      {pages > 1 && (
        <Pagination sx={{ mt: 1 }} count={pages} onChange={pageChange} />
      )}
    </Box>
  );
};

const CatalogTable = ({
  session,
  backendApi,
  handleClaimClick,
  activeOrgId,
  openProductDetail,
}: {
  session: Session;
  backendApi: BackendApi;
  handleClaimClick: (product: any) => void;
  activeOrgId: string;
  openProductDetail: (product: any) => void;
}) => {
  const [pages, setPages] = useState(1);
  const [products, setProducts] = useState([]);
  const [limit, setLimit] = useState(20);
  const [loading, setLoading] = useState(true);
  const [keyword, setKeyword] = useState("");

  const getProducts = async (page: number) => {
    const { data, count } = await backendApi.getProducts(keyword, page, limit);
    setProducts(data);
    setLoading(false);
    setPages(Math.ceil(count / limit));
  };

  const pageChange = (e, v) => {
    getProducts(v - 1);
  };

  const inputChanged = async (keyword) => {
    if (keyword.length < 1) {
      return;
    }
    setLoading(true);
    await backendApi.getProducts(keyword, 0, limit).then((res) => {
      setProducts(res.data);
      setPages(Math.ceil(res.count / limit));
    });
    setLoading(false);
  };

  const searchDelayed = debounce(inputChanged, 700);

  const onChange = (e: any) => {
    setKeyword(e.target.value);
    searchDelayed(e.target.value);
  };

  useEffect(() => {
    getProducts(0);
  }, [activeOrgId]);

  return (
    <Box>
      <TextField
        sx={{ mt: 1, mb: 1 }}
        label="Search"
        fullWidth
        value={keyword}
        placeholder="Enter product name"
        onChange={onChange}
        InputProps={{
          startAdornment: (
            <InputAdornment position="start">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <LinearProgress sx={{ display: loading ? "block" : "none", mt: 1 }} />
      <List>
        {products.length === 0 && !loading && <ListItem>No products</ListItem>}
        {products.map((product, index) => (
          <ProductRow
            sx={{}}
            product={product}
            key={index}
            onClick={() => openProductDetail(product)}
          />
        ))}
      </List>
      {pages > 1 && (
        <Pagination sx={{ mt: 1, mb: 1 }} count={pages} onChange={pageChange} />
      )}
    </Box>
  );
};

export default ProductsPage;
