import { FullscreenExit } from "@mui/icons-material";
import { Dialog, DialogContent, DialogTitle, IconButton } from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React from "react";
import BackendApi from "../BackendApi";
import Canvas from "./Canvas";

export default ({
  open,
  onClose,
  mode,
  supabase,
  session,
  activeOrgId,
  activeOrgUserId,
  backendApi,
  categories,
  canvasHeight = "calc(100vh - 67px)",
}: {
  open: boolean;
  onClose: () => void;
  mode: string;
  supabase: SupabaseClient;
  session: Session;
  activeOrgId: string;
  activeOrgUserId: string;
  backendApi: BackendApi;
  categories: Array<any>;
  canvasHeight?: string;
}) => {
  return (
    <Dialog open={open} onClose={onClose} fullScreen fullWidth>
      <DialogTitle>
        <IconButton
          sx={{ color: "black", borderColor: "gray" }}
          onClick={() => onClose()}
        >
          <FullscreenExit />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Canvas
          mode={"light"}
          supabase={supabase}
          session={session}
          activeOrgId={activeOrgId}
          activeOrgUserId={activeOrgUserId}
          backendApi={backendApi}
          categories={categories}
          canvasHeight="calc(100vh - 80px)"
        />
      </DialogContent>
    </Dialog>
  );
};
