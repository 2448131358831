import { AccountTree, Fullscreen } from "@mui/icons-material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CreditCardIcon from "@mui/icons-material/CreditCard";
import DashboardIcon from "@mui/icons-material/Dashboard";
import EmailIcon from "@mui/icons-material/Email";
import FavoriteIcon from "@mui/icons-material/Favorite";
import LockIcon from "@mui/icons-material/Lock";
import MessageIcon from "@mui/icons-material/Message";
import SettingsIcon from "@mui/icons-material/Settings";
import {
  AppBar,
  Box,
  Button,
  Container,
  Divider,
  Grid,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Toolbar,
  Typography,
  useTheme,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import { useSnackbar } from "notistack";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import BackendApi from "./BackendApi";
import Home from "./Home";
import Logo from "./Logo";
import AccountPage from "./account/AccountPage";
import Canvas from "./canvas/Canvas";
import CanvasModal from "./canvas/CanvasModal";
import { identify } from "./capture";
import Company from "./company/Company";
import Consultant from "./consultant/Consultant";
import NewChatPage from "./messaging/ChatPage";
import Organization from "./organization/Organization";
import OrganizationSettings from "./organization/OrganizationSettings";
import Products from "./products/Products";

const Sidebar = ({
  screen,
  setPageView,
}: {
  screen: string;
  setPageView: (screen: string) => void;
}) => (
  <List>
    <ListItem>
      <Typography variant="h6">Hi David</Typography>
    </ListItem>
    <ListItem>
      <Typography variant="body2">$0.00 Lifetime Cash Back</Typography>
    </ListItem>
    <ListItem>
      <Typography variant="body2">Member Since 11/10/2024</Typography>
    </ListItem>
    <ListItem>
      <Button sx={{ color: "black", borderColor: "gray" }} variant="outlined">
        Refer & Earn 10% off
      </Button>
    </ListItem>
    <ListItemButton
      selected={screen === "dashboard"}
      disabled={screen === "dashboard"}
      onClick={() => setPageView("dashboard")}
    >
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Dashboard" />
    </ListItemButton>
    <ListItemButton
      selected={screen === "account"}
      disabled={screen === "account"}
      onClick={() => setPageView("account")}
    >
      <ListItemIcon>
        <AccountCircleIcon />
      </ListItemIcon>
      <ListItemText primary="Account" />
    </ListItemButton>
    <ListItemButton
      selected={screen === "canvas"}
      disabled={screen === "canvas"}
      onClick={() => setPageView("canvas")}
    >
      <ListItemIcon>
        <AccountTree />
      </ListItemIcon>
      <ListItemText primary="Canvas" />
    </ListItemButton>
    <ListItemButton
      selected={screen === "messages"}
      disabled={screen === "messages"}
      onClick={() => setPageView("messages")}
    >
      <ListItemIcon>
        <MessageIcon />
      </ListItemIcon>
      <ListItemText primary="Messages" />
    </ListItemButton>
    <ListItemButton
      selected={screen === "settings"}
      disabled={screen === "settings"}
      onClick={() => setPageView("settings")}
    >
      <ListItemIcon>
        <SettingsIcon />
      </ListItemIcon>
      <ListItemText primary="Account Settings" />
    </ListItemButton>
    <ListItemButton
      selected={screen === "privacy"}
      disabled={screen === "privacy"}
      onClick={() => setPageView("privacy")}
    >
      <ListItemIcon>
        <LockIcon />
      </ListItemIcon>
      <ListItemText primary="Privacy Preferences" />
    </ListItemButton>
    <ListItemButton
      selected={screen === "credit-cards"}
      disabled={screen === "credit-cards"}
      onClick={() => setPageView("credit-cards")}
    >
      <ListItemIcon>
        <CreditCardIcon />
      </ListItemIcon>
      <ListItemText primary="Credit Cards" />
    </ListItemButton>
    <ListItemButton
      selected={screen === "subscriptions"}
      disabled={screen === "subscriptions"}
      onClick={() => setPageView("subscriptions")}
    >
      <ListItemIcon>
        <EmailIcon />
      </ListItemIcon>
      <ListItemText primary="Email Subscriptions" />
    </ListItemButton>
    <ListItemButton
      selected={screen === "favorites"}
      disabled={screen === "favorites"}
      onClick={() => setPageView("favorites")}
    >
      <ListItemIcon>
        <FavoriteIcon />
      </ListItemIcon>
      <ListItemText primary="Favorites" />
    </ListItemButton>
  </List>
);

const ConsumerDashboard = ({ supabase }: { supabase: SupabaseClient }) => {
  const theme = useTheme();
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const [openDrawer, setOpenDrawer] = useState(false);
  const [screen, setScreen] = useState(params.topPage || "account");
  const [session, setSession] = useState<Session | null>(null);
  const navigate = useNavigate();
  const [organizations, setOrganizations] = useState([]);
  const [pendingOrganizations, setPendingOrganizations] = useState([]);
  const [orgCreateOpen, setOrgCreateOpen] = useState(false);
  const [activeOrgId, setActiveOrgId] = useState("");
  const [activeOrgUserId, setActiveOrgUserId] = useState("");
  const [selectOrgOpen, setSelectOrgOpen] = useState(false);
  const { enqueueSnackbar } = useSnackbar();
  const [anchorElNotications, setAnchorElNotifications] =
    useState<HTMLElement | null>(null);
  const [notifications, setNotifications] = useState([]);
  const [openConfirmInvite, setOpenConfirmInvite] = useState(false);
  const [acceptInviteOrg, setAcceptInviteOrg] = useState("");
  const [categories, setCategories] = useState([]);
  const [user, setUser] = useState<any>(null);
  const [fullScreenCanvas, setFullScreenCanvas] = useState(false);
  const backendApi = new BackendApi(supabase, session);
  const mode = "light";
  const companyView = searchParams.get("companyView") || "0";

  const getUser = async () => {
    const res = await backendApi.getMe();
    setUser(res);
  };

  useEffect(() => {
    if (session && session.access_token) {
      getUser();
    }
  }, [session]);

  useEffect(() => {
    if (params.topPage) {
      setScreen(params.topPage);
    }
  }, [params]);

  const getCategories = async (session: Session) => {
    const categories = await backendApi.getCategoriesTree(session);
    setCategories(categories);
  };

  const handleNotificationsClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorElNotifications(event.currentTarget);
  };

  const handleNotificationsClose = () => {
    setAnchorElNotifications(null);
  };

  const logout = () => {
    supabase.auth.signOut();
    navigate("/");
  };

  const setPageView = (page: string) => {
    setScreen(page);
    setOpenDrawer(false);
    navigate("/admin/" + page);
  };

  useEffect(() => {
    supabase.auth
      .getSession()
      .then(({ data: { session } }) => {
        identify(session);
        setSession(session);
        getPendingOrganizations(session);
        getOrganizations(session);
        getNotifications(session);
        getCategories(session);
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
      });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      identify(session);
      setSession(session);
      getPendingOrganizations(session);
      getOrganizations(session);
      getNotifications(session);
      getCategories(session);
    });
    console.log(subscription);
    return () => subscription.unsubscribe();
  }, []);

  const getNotifications = async (sess: any, active: any = null) => {
    const currActiveOrgId =
      active || activeOrgId || sess.user.user_metadata.active_organization_id;
    const notifications = await backendApi.getNotications(
      currActiveOrgId,
      sess.user.id
    );
    setNotifications(notifications);
  };

  const getOrganizations = async (sess: any, active: any = null) => {
    const currActiveOrgId =
      active || activeOrgId || sess.user.user_metadata.active_organization_id;
    backendApi.getOrganizations(sess).then((organizations) => {
      setOrganizations(organizations);
      if (currActiveOrgId && organizations.length !== 0) {
        setActiveOrgId(currActiveOrgId);
        const selectedOrg = organizations.find(
          (x) => x.organization_id === currActiveOrgId
        );
        setActiveOrgUserId(selectedOrg.id);
      } else if (organizations.length !== 0) {
        setActiveOrgId(organizations[0].organization_id);
        setActiveOrgUserId(organizations[0].id);
      } else {
        setOrgCreateOpen(true);
      }
    });
  };

  const getPendingOrganizations = async (sess: any) => {
    const pending = await backendApi.getPendingOrganizations(sess.user.email);
    setPendingOrganizations(pending);
    pending.forEach((org: any) => {
      if (!org.accepted) {
        setOpenConfirmInvite(true);
        setAcceptInviteOrg(org.organizations.name);
      }
    });
  };

  const getActiveOrgName = () => {
    if (
      activeOrgId &&
      organizations.length !== 0 &&
      organizations.find((x) => x.organization_id === activeOrgId)
    ) {
      return organizations.find((x) => x.organization_id === activeOrgId)
        .organizations.name;
    }
    return "None";
  };

  const setActiveOrganizationId = (orgId) => {
    setActiveOrgId(orgId);
    supabase.auth.updateUser({
      data: {
        active_organization_id: orgId,
      },
    });
    setSelectOrgOpen(false);
    setAnchorEl(null);
    getOrganizations(session, orgId);
  };

  const createNewOrganization = () => {
    setSelectOrgOpen(false);
    setAnchorEl(null);
    setOrgCreateOpen(true);
  };

  const confirmOrganization = async () => {
    await backendApi.joinOrganizations(
      pendingOrganizations
        .map((x) => {
          return {
            id: x.id,
            organization_id: x.organization_id,
            role: x.role,
            accepted: x.accepted,
            user_id: session.user.id,
            name: session.user.user_metadata.name,
            photo_url: session.user.user_metadata.avatar_url,
          };
        })
        .filter((x) => !x.accepted)
    );
  };

  const getScreenName = () => {
    const mapping: any = {
      dashboard: "Dashboard",
      account: "Account",
      "credit-cards": "Credit Cards",
      settings: "Account Settings",
    };
    return (
      mapping[screen] ||
      screen.slice(0, 1).toUpperCase() + screen.slice(1, screen.length)
    );
  };

  return (
    <Box flex={"1"}>
      <AppBar
        position="static"
        elevation={0}
        sx={{ backgroundColor: "#ffffff", color: "#000000" }}
      >
        <Toolbar component={Container}>
          <Logo mode={"light"} />
          <Typography sx={{ ml: 2 }}>Categories</Typography>
          <div style={{ flexGrow: 1 }}></div>
          <IconButton color="inherit">
            <Typography variant="body1">Help</Typography>
          </IconButton>
          <Box mx={2}>
            <Typography variant="body1">David $0.00</Typography>
          </Box>
        </Toolbar>
        <Divider />
        <Toolbar sx={{ justifyContent: "center" }}>
          <Box mx={2}>
            <Typography variant="body1">Extra Cash Back</Typography>
          </Box>
          <Box mx={2}>
            <Typography variant="body1">Hot Deals</Typography>
          </Box>
          <Box mx={2}>
            <Typography variant="body1">
              Refer & Earn up to 10% off your next software purchase
            </Typography>
          </Box>
        </Toolbar>
        <Divider />
      </AppBar>
      <Container>
        <Grid container spacing={2}>
          <Grid item xs={3}>
            <Sidebar screen={screen} setPageView={setPageView} />
          </Grid>
          <Grid item xs={9}>
            <Box sx={{ mt: 4 }}>
              <Typography variant="h5">{getScreenName()}</Typography>
            </Box>
            <Box sx={{ mt: 4, mb: 4 }}>
              {screen === "canvas" && !fullScreenCanvas && (
                <Box>
                  <Button
                    sx={{ color: "black", borderColor: "gray", mb: 2 }}
                    variant={"outlined"}
                    onClick={() => setFullScreenCanvas(true)}
                    startIcon={<Fullscreen />}
                  >
                    Full Screen
                  </Button>
                  <Canvas
                    mode={"light"}
                    supabase={supabase}
                    session={session}
                    activeOrgId={activeOrgId}
                    activeOrgUserId={activeOrgUserId}
                    backendApi={backendApi}
                    categories={categories}
                    canvasHeight="calc(100vh - 407px)"
                  />
                </Box>
              )}
              {screen === "dashboard" && <Home />}
              {screen === "products" && (
                <Products
                  mode={mode}
                  supabase={supabase}
                  session={session}
                  activeOrgId={activeOrgId}
                  backendApi={backendApi}
                />
              )}
              {screen === "company" && (
                <Company
                  mode={mode}
                  supabase={supabase}
                  session={session}
                  activeOrgId={activeOrgId}
                  activeOrgUserId={activeOrgUserId}
                  backendApi={backendApi}
                />
              )}
              {screen === "organization" && (
                <Organization
                  mode={mode}
                  supabase={supabase}
                  session={session}
                  activeOrgId={activeOrgId}
                  backendApi={backendApi}
                />
              )}
              {screen === "settings" && (
                <OrganizationSettings
                  backendApi={backendApi}
                  activeOrgId={activeOrgId}
                />
              )}
              {screen === "consultant" && (
                <Consultant
                  mode={mode}
                  supabase={supabase}
                  session={session}
                  activeOrgId={activeOrgId}
                  backendApi={backendApi}
                />
              )}
              {screen === "account" && <AccountPage />}
              {screen === "messages" && user && (
                <NewChatPage
                  backendApi={backendApi}
                  session={session}
                  supabase={supabase}
                  companyView={parseInt(companyView) == 1}
                />
              )}
            </Box>
          </Grid>
        </Grid>
      </Container>
      <CanvasModal
        open={fullScreenCanvas}
        onClose={() => setFullScreenCanvas(false)}
        mode={"light"}
        supabase={supabase}
        session={session}
        activeOrgId={activeOrgId}
        activeOrgUserId={activeOrgUserId}
        backendApi={backendApi}
        categories={categories}
        canvasHeight="calc(100vh - 407px)"
      />
    </Box>
  );
};

export default ConsumerDashboard;
