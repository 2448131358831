// NewLayerDialog.tsx
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

interface NewLayerDialogProps {
  open: boolean;
  onClose: () => void;
  onConfirm: (layerName: string) => void;
}

const NewLayerDialog = ({ open, onClose, onConfirm }: NewLayerDialogProps) => {
  const [layerName, setLayerName] = useState("");

  const handleSubmit = () => {
    if (layerName.trim()) {
      onConfirm(layerName);
      setLayerName("");
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xs" fullWidth>
      <DialogTitle>Add New Layer</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Layer Name"
          fullWidth
          value={layerName}
          onChange={(e) => setLayerName(e.target.value)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              handleSubmit();
            }
          }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose}>Cancel</Button>
        <Button onClick={handleSubmit} variant="outlined" color="primary">
          Add
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default NewLayerDialog;
