import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from "@mui/material";
import React, { useEffect, useState } from "react";

export default ({
  id,
  canvasName,
  onClose,
  onSave,
}: {
  id: string;
  canvasName: string;
  onClose: () => void;
  onSave: (id: string, name: string) => void;
}) => {
  const [name, setName] = useState(canvasName);

  useEffect(() => {
    setName(canvasName);
  }, [canvasName]);

  return (
    <Dialog open={id != null} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>Canvas Edit</DialogTitle>
      <DialogContent>
        <TextField
          fullWidth
          sx={{ my: 2 }}
          value={name}
          onChange={(e) => setName(e.target.value)}
          label="Name"
          name="name"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onSave(id, name)}>Save</Button>
      </DialogActions>
    </Dialog>
  );
};
