import { CssBaseline, PaletteMode, useMediaQuery } from "@mui/material";
import { ThemeProvider, createTheme } from "@mui/material/styles";
import { createClient } from "@supabase/supabase-js";
import { SnackbarProvider } from "notistack";
import React, { useState } from "react";
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import ConsumerDashboard from "./src/ConsumerDashboard";
import Dashboard from "./src/Dashboard";
import Login from "./src/Login";

const supabase = createClient(
  "https://kupcaenotqbcsbthsfiz.supabase.co",
  "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6Imt1cGNhZW5vdHFiY3NidGhzZml6Iiwicm9sZSI6ImFub24iLCJpYXQiOjE3MDk5MzMzODEsImV4cCI6MjAyNTUwOTM4MX0.wQrnedn1SLLZlkGU2hiNxjVgk5j6BYzVbYihrmu2T6o"
);

const getTheme = (mode: PaletteMode) => {
  const primaryColor = mode === "dark" ? "#A3F7BF" : "#ffffff";
  const secondaryColor = mode === "dark" ? "#29A19C" : "#c4f3e4";
  const contrastColor = mode === "dark" ? "#fff" : "#000";

  const accentColor = "#c4f3e4";
  const theme = {
    palette: {
      mode: mode,
      background: {
        default: mode === "dark" ? "#191919" : "#fff",
      },
      primary: {
        main: primaryColor,
        contrastText: contrastColor,
      },
      secondary: {
        main: secondaryColor,
      },
    },
    components: {
      MuiAppBar: {
        styleOverrides: {
          colorPrimary: {
            backgroundColor: mode === "dark" ? "#121212" : "#fff",
          },
        },
      },
    },
  };

  var theme2 = createTheme({
    palette: {
      primary: {
        main: "#ffffff",
        light: "#333333",
        dark: "#000000",
        contrastText: "#000000",
      },
      secondary: {
        main: accentColor,
        light: accentColor,
        dark: accentColor,
        contrastText: "#000000",
      },
      background: {
        default: "#ffffff",
        paper: "#ffffff",
      },
      text: {
        primary: "#000000",
        secondary: "#000000",
      },
      action: {
        active: "##ffffff",
        selected: "#ffffff",
        hover: `${accentColor}20`, // 20 is the opacity
      },
    },
    components: {
      MuiMenuItem: {
        styleOverrides: {
          root: {
            "&:Mui-selected": {
              backgroundColor: `${accentColor}`,
              "&:hover": {
                backgroundColor: `${accentColor}30`,
              },
            },
          },
        },
      },
    },
  });
  return createTheme(theme2);
};

const theme = createTheme({
  palette: {
    primary: {
      main: "#ffffff",
    },
    secondary: {
      main: "#c4f3e4",
    },
  },
});

export default function App() {
  const [mode, setMode] = useState(
    window.localStorage.getItem("mode") || "system"
  );
  const prefersDarkMode = useMediaQuery("(prefers-color-scheme: dark)");
  const [openSnackbar, setOpenSnackbar] = useState(false);
  var theme = createTheme({
    palette: {
      primary: {
        main: "#000000",
      },
      secondary: {
        main: "#c4f3e4",
      },
    },
  });

  const setThemeMode = (mode: string) => {
    window.localStorage.setItem("mode", mode);
    setMode(mode);
  };

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider maxSnack={3}>
        <CssBaseline />
        <Router>
          <Routes>
            <Route
              path="/"
              element={<Login supabase={supabase} mode={mode} />}
            />
            <Route
              path="/dashboard"
              element={
                <Dashboard supabase={supabase} mode={mode} setMode={setMode} />
              }
            />
            <Route
              path="/dashboard/:topPage"
              element={
                <Dashboard supabase={supabase} mode={mode} setMode={setMode} />
              }
            />
            <Route
              path="/dashboard/:topPage/:secondPage"
              element={
                <Dashboard supabase={supabase} mode={mode} setMode={setMode} />
              }
            />
            <Route
              path="/admin"
              element={<ConsumerDashboard supabase={supabase} />}
            />
            <Route
              path="/admin/:topPage"
              element={<ConsumerDashboard supabase={supabase} />}
            />
            <Route
              path="/admin/:topPage/:secondPage"
              element={<ConsumerDashboard supabase={supabase} />}
            />
          </Routes>
        </Router>
      </SnackbarProvider>
    </ThemeProvider>
  );
}
