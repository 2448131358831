import {
  Box,
  Dialog,
  Grid,
  IconButton,
  Paper,
  styled,
  TextField,
} from "@mui/material";
import {
  RealtimeChannel,
  Session,
  SupabaseClient,
} from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import { SendIcon } from "stream-chat-react";
import BackendApi from "../BackendApi";
import ChatMessages from "./ChatMessages";

const ChatContainer = styled(Box)(({ theme }) => ({
  height: "60vh",
  backgroundColor: theme.palette.background.default,
  border: `1px solid ${theme.palette.divider}`,
}));

const ConversationsList = styled(Paper)(({ theme }) => ({
  height: "100%",
  borderRight: `1px solid ${theme.palette.divider}`,
}));

const ChatArea = styled(Paper)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
}));

const MessageInput = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  borderTop: `1px solid ${theme.palette.divider}`,
}));

export default ({
  open,
  onClose,
  backendApi,
  session,
  supabase,
  selectedConversation,
  companyId,
}: {
  open: boolean;
  onClose: () => void;
  backendApi: BackendApi;
  session: Session;
  supabase: SupabaseClient;
  selectedConversation: any;
  companyId: string;
}) => {
  const [message, setMessage] = useState("");
  const [messages, setMessages] = useState([]);
  const [loading, setLoading] = useState(true);
  const [channel, setChannel] = useState<RealtimeChannel>(null);

  const getConversationMessages = async () => {
    if (!selectedConversation || !selectedConversation.id) return;
    setLoading(true);
    const messages = await backendApi.getConversationMessages(
      selectedConversation.id
    );
    setMessages(messages);
    const chan = supabase
      .channel(selectedConversation.id)
      .on("broadcast", { event: "change" }, ({ payload }) => {
        getConversationMessages();
      })
      .subscribe((status) => {
        console.log("Subscribed to conversation changes", status);
      });
    setChannel(chan);
    setLoading(false);
  };

  const sendMessage = async (e) => {
    e.preventDefault();
    if (!Object.keys(selectedConversation) || !message) return;
    await backendApi.sendConversationMessage(
      selectedConversation.id,
      companyId,
      message
    );
    setMessage("");
    getConversationMessages();
    channel.send({
      type: "broadcast",
      event: "change",
      payload: {
        type: "new_message",
      },
    });
  };

  useEffect(() => {
    getConversationMessages();
  }, [selectedConversation]);

  return (
    <Dialog open={open} onClose={onClose} fullWidth maxWidth="md">
      <ChatArea elevation={0}>
        {/* Messages Area */}
        <Box sx={{ flexGrow: 1, p: 2, overflowY: "auto" }}>
          <ChatMessages messages={messages} companyView={true} />
        </Box>

        {/* Message Input */}
        <MessageInput>
          <form onSubmit={sendMessage}>
            <Grid container spacing={2}>
              <Grid item xs={11}>
                <TextField
                  fullWidth
                  placeholder="Type a message..."
                  value={message}
                  onChange={(e: any) => setMessage(e.target.value)}
                />
              </Grid>
              <Grid
                item
                xs={1}
                sx={{ display: "flex", justifyItems: "center" }}
              >
                <IconButton color="default" type="submit">
                  <SendIcon />
                </IconButton>
              </Grid>
            </Grid>
          </form>
        </MessageInput>
      </ChatArea>
    </Dialog>
  );
};
