import {
  Button,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  styled,
  Typography,
} from "@mui/material";
import React from "react";
import CompanyLogo from "../company/CompanyLogo";

export const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: 13,
  alignItems: "center",
  backgroundColor: theme.palette.background.paper,
  borderRadius: 30,
  minWidth: "50vw",
}));

export default ({
  nodes,
  aiMessages,
  applyNode,
  clearSuggestions,
  applyAll,
}) => {
  return (
    <List sx={{ maxHeight: "50vh", overflow: "scroll" }}>
      {aiMessages.map((msg) => (
        <ListItem key={msg}>
          <StyledPaper elevation={8}>
            <Typography sx={{ p: 1 }}>{msg}</Typography>
          </StyledPaper>
        </ListItem>
      ))}
      {nodes.map((node) => (
        <ListItem key={node.id}>
          <StyledPaper elevation={8} sx={{ display: "flex" }}>
            <ListItemAvatar>
              <CompanyLogo product={node} sx={{}} />
            </ListItemAvatar>
            <ListItemText
              sx={{ display: "block" }}
              primary={node.name}
              primaryTypographyProps={{
                style: {
                  display: "block",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                },
              }}
            />
            <Button onClick={() => applyNode(node)}>Apply Suggestion</Button>
          </StyledPaper>
        </ListItem>
      ))}
      <ListItem
        sx={{ display: nodes.length && nodes.length > 1 ? "block" : "none" }}
      >
        <StyledPaper elevation={8}>
          <Button fullWidth onClick={applyAll}>
            Apply All
          </Button>
        </StyledPaper>
      </ListItem>
      <ListItem
        sx={{ display: nodes.length || aiMessages.length ? "block" : "none" }}
      >
        <StyledPaper elevation={8}>
          <Button fullWidth onClick={clearSuggestions}>
            Clear
          </Button>
        </StyledPaper>
      </ListItem>
    </List>
  );
};
