import React, { useCallback, useEffect, useMemo, useState } from "react";
import ReactFlow, {
  Background,
  Panel,
  addEdge,
  useEdgesState,
  useNodesState,
} from "reactflow";

import {
  CloudDone,
  CloudUpload,
  Groups,
  LayersOutlined,
  ListTwoTone,
  PolylineOutlined,
  Search,
} from "@mui/icons-material";
import { Box, IconButton, Tooltip, debounce, useTheme } from "@mui/material";
import {
  RealtimeChannel,
  Session,
  SupabaseClient,
} from "@supabase/supabase-js";
import { useSnackbar } from "notistack";
import "reactflow/dist/style.css";
import { v4 as uuidv4 } from "uuid";
import BackendApi from "../BackendApi";
import CollaboratorsInviteDialog from "../collaborator/CollaboratorsInviteDialog";
import CollaboratorsDialog from "../collaborator/CollaboratorsMenu";
import ProductDetailDialog from "../products/ProductDetailDialog";
import AIInput from "./ai/AiInput";
import CanvasDrawer from "./CanvasDrawer";
import CanvasEditModal from "./CanvasEditModal";
import CanvasStagedChanges from "./CanvasStagedChanges";
import CompanyCanvasNode from "./CompanyCanvasNode";
import LayersSidebar from "./layers/LayersSidebar";
import { getLayoutedElements } from "./layout/LayoutElements";
import ProductPopup from "./products/ProductPopup";
import ProductsSidebar from "./products/ProductsSidebar";
import SearchOverlay from "./search/SearchOverlay";
import SearchSidepanel from "./search/SearchSidepanel";

const connectionHelper = {
  segment: ["salesforce-sales-cloud", "sap-sales-cloud", "hubspot-sales-hub"],
  "hubspot-sales-hub": ["segment"],
  "salesforce-sales-cloud": ["segment"],
  "sap-sales-cloud": ["segment"],
};

const generateDefaultDocName = () => {
  const prefix = "Untitled Canvas";
  const timestamp = Date.now().toString();
  return `${prefix} ${timestamp}`;
};

export default ({
  mode,
  supabase,
  session,
  activeOrgId,
  activeOrgUserId,
  backendApi,
  categories,
  canvasHeight = "calc(100vh - 67px)",
}: {
  mode: string;
  supabase: SupabaseClient;
  session: Session;
  activeOrgId: string;
  activeOrgUserId: string;
  backendApi: BackendApi;
  categories: Array<any>;
  canvasHeight?: string;
}) => {
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  const [reactFlowInstance, setReactFlowInstance] = useState(null);
  const [canvasList, setCanvasList] = useState([]);
  const [drawerOpen, setDrawerOpen] = useState(false);
  const [canvas, setCanvas] = useState<any>({ canvases: { name: "" } });
  const [saving, setSaving] = useState(false);
  const [products, setProducts] = useState([]);
  const [collaboratorsOpen, setCollaboratorsOpen] = useState(false);
  const [canvasNodeOpen, setCanvasNodeOpen] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState<any>({});
  const [aiDialogOpen, setAiDialogOpen] = useState(false);
  const [inviteDialogOpen, setInviteDialogOpen] = useState(false);
  const [refreshCollaborators, setRefreshCollaborators] = useState(false);
  const [searchOpen, setSearchOpen] = useState(false);
  const [channel, setChannel] = useState<RealtimeChannel>(null);
  const [stagedNodes, setStagedNodes] = useState([]);
  const [stagedEdges, setStagedEdges] = useState([]);
  const [aiMessages, setAiMessages] = useState([]);
  const [nodeSelectedOpen, setNodeSelectedOpen] = useState(false);
  const [productTab, setProductTab] = useState("description");
  const [selectedNode, setSelectedNode] = useState<any>({});
  const [layersOpen, setLayersOpen] = useState(false);
  const [layerMap, setLayerMap] = useState({});
  const [searchOverlay, setSearchOverlay] = useState(false);
  const [aiSuggestion, setAiSuggestions] = useState<any>({
    suggestedProducts: [],
  });
  const [newProductAdd, setNewProductAdd] = useState<any>({});
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const onDragStart = (event, nodeType, product) => {
    setProducts([products, product]);
    event.dataTransfer.setData("application/reactflow", nodeType);
    event.dataTransfer.effectAllowed = "move";
  };

  const onDragOver = useCallback((event) => {
    event.preventDefault();
    event.dataTransfer.dropEffect = "move";
  }, []);

  const onNodeSave = async (nodeId: string, data: any) => {
    setSelectedNode({ id: nodeId, data });
    setNodes((ns) =>
      ns.map((n) => {
        if (n.id === nodeId) {
          return { ...n, data: data };
        }
        return n;
      })
    );
  };

  const setActiveCanvas = (canvasList) => {
    if (
      session.user.user_metadata.active_canvas_id &&
      canvasList.find(
        (x) => x.canvas_id === session.user.user_metadata.active_canvas_id
      )
    ) {
      const can = canvasList.find(
        (x) => x.canvas_id === session.user.user_metadata.active_canvas_id
      );
      setCanvas(can);
      getCanvas(can);
      setupSubscription(canvas, session);
    } else if (canvasList.length > 0) {
      const canvas = canvasList[0];
      supabase.auth.updateUser({
        data: { active_canvas_id: canvas.canvas_id },
      });
      setCanvas(canvas);
      getCanvas(canvas);
      setupSubscription(canvas, session);
    } else {
      createNewCanvas();
    }
  };

  const createNewCanvas = () => {
    const canvasId = uuidv4();
    const newCanvas = {
      id: -1,
      role: "owner",
      canvas_id: canvasId,
      organization_user_id: activeOrgUserId,
      canvases: {
        id: canvasId,
        organization_id: activeOrgId,
        name: generateDefaultDocName(),
        created_by: session.user.id,
      },
    };
    setNodes([]);
    setEdges([]);
    setCanvas(newCanvas);
    setCanvasList((cl) => cl.concat(newCanvas));
    setupSubscription(newCanvas, session);
  };

  const getCanvasList = async () => {
    if (!session || !session.user || !activeOrgId) {
      return;
    }
    const canvasList = await backendApi.getCanvasList(
      session.user.id,
      activeOrgId
    );
    setCanvasList(canvasList);
    setActiveCanvas(canvasList);
  };

  const selectedSearchOption = (event: any, product: any) => {
    event.preventDefault();

    // check if the dropped element is valid
    if (typeof product === "undefined" || !product) {
      return;
    }
    const position = reactFlowInstance.screenToFlowPosition({
      x: 400,
      y: 400,
    });
    const newNode = {
      id: uuidv4(),
      type: "companyCanvasNode",
      position,
      data: {
        logo_url: product.logo_url,
        label: product.name,
        value: product.value,
        product_id: product.id,
        categories: product.categories,
        category_id: product.category_id,
        canvas_id: canvas.canvas_id,
        created_by: session.user.id,
        onView: onNodeView,
        onDelete: onNodeDelete,
        onSave: onNodeSave,
      },
    };

    if (product.company_id) {
      setNewProductAdd({
        product,
        canvasNodeId: newNode.id,
        canvasId: canvas.canvas_id,
      });
    }

    // find any nodes that connect
    const newEdges = [];
    setNodes((nds) => {
      channel.send({
        type: "broadcast",
        event: "change",
        payload: {
          user_id: session.user.id,
          nodes: nds.concat(newNode),
          edges: edges,
        },
      });
      return nds.concat(newNode);
    });
    setEdges((eds) => eds.concat(newEdges));
  };

  const addProductToCanvas = async (productId: string) => {
    setSearchOverlay(false);
    const product = await backendApi.getProduct(productId);

    if (typeof product === "undefined" || !product) {
      return;
    }

    const position = reactFlowInstance.screenToFlowPosition({
      x: 400,
      y: 400,
    });
    const newNode = {
      id: uuidv4(),
      type: "companyCanvasNode",
      position,
      data: {
        logo_url: product.logo_url,
        label: product.name,
        value: product.value,
        product_id: product.id,
        categories: product.categories,
        category_id: product.category_id,
        canvas_id: canvas.canvas_id,
        created_by: session.user.id,
        onView: onNodeView,
        onDelete: onNodeDelete,
        onSave: onNodeSave,
      },
    };

    if (product.company_id) {
      setNewProductAdd({
        product,
        canvasNodeId: newNode.id,
        canvasId: canvas.canvas_id,
      });
    }

    // find any nodes that connect
    const newEdges = [];
    setNodes((nds) => {
      channel.send({
        type: "broadcast",
        event: "change",
        payload: {
          user_id: session.user.id,
          nodes: nds.concat(newNode),
          edges: edges,
        },
      });
      return nds.concat(newNode);
    });
    setEdges((eds) => eds.concat(newEdges));
  };

  const onDrop = useCallback(
    (event, user_id, canvas_id) => {
      event.preventDefault();

      const productId = event.dataTransfer.getData("application/reactflow");
      const product = products.find((x) => x.id === productId);

      // check if the dropped element is valid
      if (typeof product === "undefined" || !product) {
        return;
      }

      const position = reactFlowInstance.screenToFlowPosition({
        x: event.clientX,
        y: event.clientY,
      });
      const newNode = {
        id: uuidv4(),
        type: "companyCanvasNode",
        position,
        data: {
          logo_url: product.logo_url,
          label: product.name,
          value: product.value,
          categories: product.categories,
          product_id: productId,
          canvas_id: canvas_id,
          created_by: user_id,
          onView: onNodeView,
          onDelete: onNodeDelete,
          onSave: onNodeSave,
        },
      };

      if (product.company_id) {
        setNewProductAdd({
          product,
          canvasNodeId: newNode.id,
          canvasId: canvas.canvas_id,
        });
      }

      // find any nodes that connect
      const newEdges = [];
      setNodes((nds) => {
        nds.forEach((node) => {
          if (
            node.data.value in connectionHelper &&
            connectionHelper[node.data.value].includes(product.value)
          ) {
            newEdges.push({
              id: uuidv4(),
              source: newNode.id,
              target: node.id,
              canvas_id: canvas_id,
              created_by: user_id,
              animated: true,
            });
          }
        });
        channel.send({
          type: "broadcast",
          event: "change",
          payload: {
            user_id: session.user.id,
            nodes: nds.concat(newNode),
            edges: edges,
          },
        });

        return nds.concat(newNode);
      });
      setEdges((eds) => eds.concat(newEdges));
    },
    [reactFlowInstance, products]
  );

  const saveCanvasUser = async (can, sess) => {
    if (can.id !== -1) {
      return;
    }
    try {
      const canvasUserId = uuidv4();
      const { data, error } = await supabase
        .from("canvas_users")
        .insert({
          id: canvasUserId,
          organization_user_id: can.organization_user_id,
          user_id: sess.user.id,
          canvas_id: can.canvas_id,
          role: can.role,
        })
        .single();

      if (error) {
        throw new Error(error.message);
      }

      setCanvas({ ...can, id: canvasUserId });
      return data;
    } catch (error) {
      enqueueSnackbar("Error saving canvas user");
    }
  };

  const saveCanvasRecord = async (can, sess) => {
    try {
      const record = {
        id: can.canvas_id,
        name: can.canvases.name,
        organization_id: can.canvases.organization_id,
        updated_by: sess.user.id,
        created_by: can.canvases.created_by,
      };
      if (can.id !== -1) {
        var { data, error } = await supabase
          .from("canvases")
          .upsert(record)
          .eq("id", record.id)
          .single();
      } else {
        var { data, error } = await supabase
          .from("canvases")
          .insert(record)
          .single();
      }

      if (error) {
        throw new Error(error.message);
      }
      return data;
    } catch (error) {
      enqueueSnackbar("Error saving canvas");
    }
  };

  const convertNodesToCanvasNodes = (nodes, sess) => {
    const records = nodes.map((x) => {
      return convertNodeToCanvasNode(x, sess);
    });
    return records;
  };

  const convertNodeToCanvasNode = (node, sess) => {
    return {
      id: node.id,
      name: node.data.name,
      product_id: node.data.product_id,
      canvas_id: node.data.canvas_id,
      created_by: node.data.created_by,
      updated_by: sess.user.id,
      label: node.data.label,
      value: node.data.value,
      logo_url: node.data.logo_url,
      description: node.data.description,
      position_x: node.position.x,
      position_y: node.position.y,
      type: node.type,
    };
  };

  const convertEdgeToCanvasEdge = (edge, sess) => {
    return {
      id: edge.id,
      source: edge.source,
      target: edge.target,
      canvas_id: edge.canvas_id,
      created_by: edge.created_by,
      updated_by: sess.user.id,
    };
  };

  const saveCanvasNodes = async (can, nodes, sess) => {
    try {
      const records = convertNodesToCanvasNodes(nodes, sess);
      const { data, error } = await backendApi.saveCanvasNodes(records);

      if (error) {
        throw new Error(error.message);
      }
      return data;
    } catch (error) {
      enqueueSnackbar("Error saving canvas nodes");
    }
  };

  const saveCanvasEdges = async (can, edges, sess) => {
    try {
      const records = edges.map((x) => {
        return {
          id: x.id,
          canvas_id: x.canvas_id,
          created_by: x.created_by,
          updated_by: sess.user.id,
          animated: x.animated,
          source: x.source,
          target: x.target,
        };
      });

      const { data, error } = await backendApi.saveCanvasEdges(records);

      if (error) {
        throw new Error(error.message);
      }
      return data;
    } catch (error) {
      enqueueSnackbar("Error saving canvas edges");
    }
  };

  const saveCanvas = (can, nodes, edges, sess, channel) => {
    setSaving(true);
    saveCanvasRecord(can, sess)
      .then((res) => {
        return saveCanvasUser(can, sess);
      })
      .then(() => {
        return saveCanvasNodes(can, nodes, sess);
      })
      .then(() => {
        return saveCanvasEdges(can, edges, sess);
      })
      .then(() => {
        setSaving(false);
      });
  };

  const setupSubscription = (can: any, sess: any) => {
    const chan = supabase
      .channel(can.canvas_id)
      .on("broadcast", { event: "change" }, ({ payload }) => {
        if (payload.user_id !== sess.user.id) {
          setNodes(payload.nodes);
          setEdges(payload.edges);
        }
      })
      .subscribe((status) => {
        console.log("Subscribed to changes", status);
      });
    setChannel(chan);
  };

  useEffect(() => {
    getCanvasList();
  }, [session, activeOrgId]);

  const saveCanvasDebounce = useCallback(
    debounce((can, nodes, edges, sess, channel) => {
      saveCanvas(can, nodes, edges, sess, channel);
    }, 3000),
    []
  );

  const getCanvas = async (can: any) => {
    const res = await backendApi.getCanvas(can.canvas_id);
    const formattedNodes = res.nodes.map((x) => {
      return {
        id: x.id,
        type: x.type,
        position: { x: x.position_x, y: x.position_y },
        data: {
          name: x.name,
          canvas_id: x.canvas_id,
          product_id: x.product_id,
          categories: (x.product || {}).categories,
          label: (x.product || {}).label || x.label,
          value: x.value,
          logo_url: (x.product || {}).logo_url || x.logo_url,
          description: x.description,
          created_by: x.created_by,
          updated_by: x.updated_by,
          onView: onNodeView,
          onDelete: onNodeDelete,
          onSave: onNodeSave,
        },
      };
    });
    const formattedEdges = res.edges.map((x) => {
      return {
        id: x.id,
        source: x.source,
        target: x.target,
        canvas_id: x.canvas_id,
        animated: x.animated,
        created_by: x.created_by,
        updated_by: x.updated_by,
      };
    });
    setEdges(formattedEdges);
    setNodes(formattedNodes);
  };

  const setCurrentCanvas = (can: any) => {
    supabase.auth.updateUser({
      data: { active_canvas_id: can.canvas_id },
    });
    setCanvas(can);
    getCanvas(can);
  };

  const nodeTypes = useMemo(
    () => ({ companyCanvasNode: CompanyCanvasNode }),
    []
  );

  const onEdgesChangeEvent = useCallback(
    (e) => {
      onEdgesChange(e);
      if (session.user) {
        channel.send({
          type: "broadcast",
          event: "change",
          payload: {
            user_id: session.user.id,
            nodes: nodes,
            edges: edges,
          },
        });
      }
      saveCanvasDebounce(canvas, nodes, edges, session, channel);
    },
    [canvas, nodes, edges, session]
  );

  const onNodesChangeEvent = useCallback(
    (e) => {
      onNodesChange(e);
      const isDimentionChange =
        e.filter((x) => x.type === "dimensions").length === e.length;
      if (!isDimentionChange) {
        channel.send({
          type: "broadcast",
          event: "change",
          payload: {
            user_id: session.user.id,
            nodes: nodes,
            edges: edges,
          },
        });
        saveCanvasDebounce(canvas, nodes, edges, session, channel);
      }
    },
    [canvas, nodes, edges, session]
  );

  const onNodeDelete = async (nodeId: string) => {
    setNodes((ns) => {
      onNodesDelete(ns.filter((n) => n.id === nodeId));
      return ns.filter((n) => n.id !== nodeId);
    });
  };

  const onNodeView = async (id: string, data: any) => {
    console.log(id, data);
    const product = await backendApi.getProduct(data.product_id);
    setSelectedProduct(product);
    setSelectedNode({ id, data });
    setSearchOpen(false);
    setDrawerOpen(false);
    setLayersOpen(false);
  };

  const onConnect = useCallback(
    (params) => {
      const newEdge = {
        ...params,
        id: uuidv4(),
        canvas_id: canvas.canvas_id,
        created_by: session.user.id,
        updated_by: session.user.id,
      };
      backendApi.saveCanvasEdges([convertEdgeToCanvasEdge(newEdge, session)]);
      setEdges((eds) => {
        const e = addEdge(newEdge, eds);
        channel.send({
          type: "broadcast",
          event: "change",
          payload: {
            user_id: session.user.id,
            nodes: nodes,
            edges: e,
          },
        });
        return e;
      });
      onEdgesChange([]);
    },
    [canvas, session, nodes]
  );

  const onEdgesDelete = useCallback(
    (deleted) => {
      const edgesToDelete = {};
      deleted.forEach((edge) => {
        edgesToDelete[edge.id] = edge;
      });
      backendApi.saveCanvasEdges(
        Object.values(edgesToDelete).map((e: any) => {
          return {
            ...convertEdgeToCanvasEdge(e, session),
            deleted_date: new Date(),
          };
        })
      );
      setEdges((ed) => ed.filter((e) => !(e.id in edgesToDelete)));
    },
    [edges, session]
  );

  const onNodesDelete = useCallback(
    (deleted) => {
      backendApi.saveCanvasNodes(
        deleted.map((node) => {
          return {
            ...convertNodeToCanvasNode(node, session),
            deleted_date: new Date(),
            updated_by: session.user.id,
          };
        })
      );
      setEdges((ed: any) => {
        const edgesToDelete = {};
        const nodesToDelete = {};
        deleted.forEach((node) => {
          nodesToDelete[node.id] = node;
        });
        ed.forEach((edge) => {
          if (edge.source in nodesToDelete || edge.target in nodesToDelete) {
            edgesToDelete[edge.id] = edge;
          }
        });
        backendApi.saveCanvasEdges(
          Object.values(edgesToDelete).map((e: any) => {
            return {
              ...convertEdgeToCanvasEdge(e, session),
              deleted_date: new Date(),
            };
          })
        );
        return ed.filter((e) => !(e.id in edgesToDelete));
      });
    },
    [nodes, edges, session, canvas]
  );

  const handleAddCollaborator = async (organizationUser: any, role: string) => {
    await backendApi.addCanvasUser(canvas.canvas_id, {
      ...organizationUser,
      role,
    });
    setRefreshCollaborators(true);
  };

  const canvasNameChanged = (e) => {
    setCanvas({
      ...canvas,
      canvases: { ...canvas.canvases, name: e.target.value },
    });
    canvas.canvases.name = e.target.value;
    setCanvasList(
      canvasList.map((c) => {
        if (c.canvas_id === canvas.canvas_id) {
          return {
            ...c,
            canvases: {
              ...c.canvases,
              name: e.target.value,
            },
          };
        }
        return c;
      })
    );
    saveCanvasDebounce(canvas, nodes, edges, session, channel);
  };

  const addStagedNode = (node) => {
    setStagedNodes(stagedNodes.filter((x) => x.node_id !== node.node_id));
    setNodes((ns) => {
      const position = reactFlowInstance.screenToFlowPosition({
        x: 400,
        y: 400,
      });
      return ns.concat({
        id: node.node_id,
        type: "companyCanvasNode",
        position,
        data: {
          logo_url: node.logo_url,
          label: node.name,
          value: node.value,
          product_id: node.id,
          canvas_id: canvas.canvas_id,
          created_by: session.user.id,
          onView: onNodeView,
          onDelete: onNodeDelete,
          onSave: onNodeSave,
        },
      });
    });
    const selectedEdges = stagedEdges.filter(
      (x) => x.source === node.node_id || x.target === node.node_id
    );
    setStagedEdges(
      stagedEdges.filter(
        (x) => x.source !== node.node_id && x.target !== node.node_id
      )
    );

    setEdges((es) => {
      const newEdges = selectedEdges.map((e) => {
        return {
          id: uuidv4(),
          canvas_id: canvas.canvas_id,
          created_by: session.user.id,
          updated_by: session.user.id,
          animated: true,
          source: e.source,
          target: e.target,
        };
      });
      return es.concat(newEdges);
    });
  };

  const onLayout = useCallback(
    (direction) => {
      const { nodes: layoutedNodes, edges: layoutedEdges } =
        getLayoutedElements(nodes, edges, direction);

      setNodes([...layoutedNodes]);
      setEdges([...layoutedEdges]);
    },
    [nodes, edges]
  );

  const onNodeClick = async (event, node) => {
    const product = await backendApi.getProduct(node.data.product_id);
    setSelectedProduct(product);
    setSelectedNode(node);
    setNodeSelectedOpen(true);
    setSearchOpen(false);
    setDrawerOpen(false);
    setLayersOpen(false);
  };

  const onLayersClick = async (open) => {
    setNodeSelectedOpen(false);
    setSearchOpen(false);
    setDrawerOpen(false);
    setLayersOpen(open);
  };

  const clearSuggestions = () => {
    setStagedEdges([]);
    setStagedNodes([]);
    setAiMessages([]);
  };

  const applyAll = () => {
    setNodes((ns) => {
      const position = reactFlowInstance.screenToFlowPosition({
        x: 400,
        y: 400,
      });
      const newNodes = stagedNodes.map((node) => {
        return {
          id: node.node_id,
          type: "companyCanvasNode",
          position,
          data: {
            logo_url: node.logo_url,
            label: node.name,
            value: node.value,
            product_id: node.id,
            canvas_id: canvas.canvas_id,
            created_by: session.user.id,
            onView: onNodeView,
            onDelete: onNodeDelete,
            onSave: onNodeSave,
          },
        };
      });
      return ns.concat(newNodes);
    });

    setEdges((es) => {
      const newEdges = stagedEdges.map((e) => {
        return {
          id: uuidv4(),
          canvas_id: canvas.canvas_id,
          created_by: session.user.id,
          updated_by: session.user.id,
          animated: true,
          source: e.source,
          target: e.target,
        };
      });
      return es.concat(newEdges);
    });
    setStagedEdges([]);
    setStagedNodes([]);
    setAiMessages([]);
  };

  const addAiResponse = (newNodes, newEdges) => {
    setStagedNodes(newNodes);
    setStagedEdges(newEdges);
  };

  const [editCanvasId, setEditCanvasId] = useState<string | null>(null);

  const editCanvas = (id: string) => {
    setEditCanvasId(id);
  };

  const onSaveCanvas = (id: string, name: string) => {
    setEditCanvasId(null);
    setCanvas({
      ...canvas,
      canvases: { ...canvas.canvases, name: name },
    });
    canvas.canvases.name = name;
    setCanvasList(
      canvasList.map((c) => {
        if (c.canvas_id === id) {
          return {
            ...c,
            canvases: {
              ...c.canvases,
              name: name,
            },
          };
        }
        return c;
      })
    );
    saveCanvasRecord(canvas, session);
  };

  if (!session || !session.user || !activeOrgId) {
    return <div></div>;
  }

  return (
    <Box>
      <ProductPopup
        backendApi={backendApi}
        product={newProductAdd.product}
        onClose={() => setNewProductAdd({})}
        canvasId={newProductAdd.canvasId}
        canvasNodeId={newProductAdd.canvasNodeId}
      />
      <SearchOverlay
        addToCanvas={addProductToCanvas}
        aiSuggestion={aiSuggestion}
        open={searchOverlay}
        onClose={() => setSearchOverlay(false)}
      />
      <CanvasEditModal
        id={editCanvasId}
        canvasName={canvas.canvases.name}
        onClose={() => setEditCanvasId(null)}
        onSave={onSaveCanvas}
      />
      <ProductDetailDialog
        open={canvasNodeOpen}
        onClose={() => setCanvasNodeOpen(false)}
        product={selectedProduct}
        backendApi={backendApi}
        activeOrgId={activeOrgId}
        session={session}
      />
      <CollaboratorsDialog
        open={collaboratorsOpen}
        onClose={() => setCollaboratorsOpen(false)}
        canvasId={canvas.canvas_id}
        mode={mode}
        supabase={supabase}
        session={session}
        activeOrgId={activeOrgId}
        setInviteDialogOpen={(o) => setInviteDialogOpen(o)}
        refreshCollaborators={refreshCollaborators}
      />
      <CollaboratorsInviteDialog
        open={inviteDialogOpen}
        onClose={() => setInviteDialogOpen(false)}
        onInvite={handleAddCollaborator}
        mode={mode}
        activeOrgId={activeOrgId}
        canvasId={canvas.canvas_id}
        supabase={supabase}
        session={session}
      />
      <Box
        sx={{
          display: "flex",
          flexDirection: "row",
        }}
      >
        <SearchSidepanel
          open={searchOpen}
          session={session}
          supabase={supabase}
          onDragStart={onDragStart}
          selectedSearchOption={selectedSearchOption}
        />
        <CanvasDrawer
          canvasList={canvasList}
          open={drawerOpen}
          canvas={canvas}
          setCanvas={setCurrentCanvas}
          createNewCanvas={createNewCanvas}
          canvasNameChanged={canvasNameChanged}
          editCanvas={editCanvas}
        />
        <Box sx={{ height: canvasHeight, width: "100%" }}>
          <ReactFlow
            nodeTypes={nodeTypes}
            defaultEdgeOptions={{
              animated: true,
            }}
            minZoom={0.1}
            nodes={nodes}
            edges={edges}
            onNodeClick={onNodeClick}
            onNodesChange={onNodesChangeEvent}
            onNodesDelete={onNodesDelete}
            onEdgesChange={onEdgesChangeEvent}
            onEdgesDelete={onEdgesDelete}
            onInit={setReactFlowInstance}
            onConnect={onConnect}
            onDrop={(e) => onDrop(e, session.user.id, canvas.canvas_id)}
            onDragOver={onDragOver}
            fitView
            proOptions={{ hideAttribution: true }}
          >
            {/* <ControlsStyled mode={mode} />
            <MiniMapStyled mode={mode} /> */}
            <Panel position="top-right">
              <Tooltip title="Layers Menu">
                <IconButton onClick={() => onLayersClick(!layersOpen)}>
                  <LayersOutlined />
                </IconButton>
              </Tooltip>
              <Tooltip title="Auto layout">
                <IconButton onClick={() => onLayout("LR")}>
                  <PolylineOutlined />
                </IconButton>
              </Tooltip>
              {saving ? (
                <IconButton>
                  <CloudUpload />
                </IconButton>
              ) : (
                <IconButton>
                  <CloudDone />
                </IconButton>
              )}
              <IconButton onClick={() => setCollaboratorsOpen(true)}>
                <Groups />
              </IconButton>
            </Panel>
            <Panel position="bottom-center">
              <CanvasStagedChanges
                nodes={stagedNodes}
                aiMessages={aiMessages}
                applyNode={addStagedNode}
                clearSuggestions={clearSuggestions}
                applyAll={applyAll}
              />
              <AIInput
                backendApi={backendApi}
                activeOrgId={activeOrgId}
                session={session}
                nodes={nodes}
                edges={edges}
                addAiResponse={addAiResponse}
                setAiMessages={setAiMessages}
                setAiSuggestion={(suggestion) => {
                  setAiSuggestions(suggestion);
                  setSearchOverlay(true);
                }}
              />
            </Panel>
            <Panel position="top-left">
              <Box>
                <IconButton
                  onClick={() => setSearchOpen(!searchOpen)}
                  sx={{ mb: 1 }}
                >
                  <Search />
                </IconButton>
                <IconButton
                  onClick={() => setDrawerOpen(!drawerOpen)}
                  sx={{ mb: 1 }}
                >
                  <ListTwoTone />
                </IconButton>
              </Box>
            </Panel>
            <Background gap={12} size={1} />
          </ReactFlow>
        </Box>
        <LayersSidebar
          open={layersOpen}
          onClose={() => setLayersOpen(false)}
          layerMap={layerMap}
          setLayerMap={setLayerMap}
          setNodes={setNodes}
          setEdges={setEdges}
          activeCanvasId={canvas.canvas_id}
          backendApi={backendApi}
          categories={categories}
        />
        <ProductsSidebar
          session={session}
          activeCanvasId={canvas.canvas_id}
          selectedProduct={selectedProduct}
          nodeSelectedOpen={nodeSelectedOpen}
          productTab={productTab}
          selectedNode={selectedNode}
          setProductTab={setProductTab}
          setSelectedProduct={setSelectedProduct}
          backendApi={backendApi}
        />
      </Box>
    </Box>
  );
};
