import {
  Article,
  AttachMoney,
  Cancel,
  CloudUpload,
  Description,
} from "@mui/icons-material";
import {
  Box,
  Button,
  Chip,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import { formatDateTime } from "../utils";

export default ({
  session,
  canvasNodeId,
  canvasId,
  backendApi,
}: {
  session;
  canvasNodeId: string;
  canvasId: string;
  backendApi: BackendApi;
}) => {
  const [attachments, setAttachments] = useState([]);
  const [uploading, setUploading] = useState(false);

  const getAttachments = async () => {
    const { attachments } = await backendApi.getAttachments(
      canvasId,
      canvasNodeId
    );
    setAttachments(attachments);
  };

  const uploadAttachment = async (e: any) => {
    const file = e.target.files[0];
    await backendApi.saveAttachment(canvasId, canvasNodeId, file);
    await getAttachments();
  };

  useEffect(() => {
    getAttachments();
  }, [canvasNodeId]);

  const getFileIcon = (type) => {
    switch (type) {
      case "contract":
        return <Article color="primary" />;
      case "sales":
        return <AttachMoney color="secondary" />;
      default:
        return <Description />;
    }
  };

  const deleteAttachment = async (e) => {
    console.log("Delete attachemnt");
  };

  const getStatusColor = (status) => {
    switch (status) {
      case "Active":
        return "success";
      case "Draft":
        return "warning";
      case "Pending":
        return "info";
      case "Approved":
        return "success";
      default:
        return "default";
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Button
        sx={{ mt: 2 }}
        variant="outlined"
        fullWidth
        startIcon={uploading ? <CircularProgress size={20} /> : <CloudUpload />}
        disabled={uploading}
        component="label"
      >
        Upload File
        <input
          id="file-upload"
          type="file"
          onChange={uploadAttachment}
          hidden
        />
      </Button>
      <List>
        {attachments.map((doc, index) => (
          <React.Fragment key={doc.id}>
            <ListItem
              sx={{
                "&:hover": {
                  bgcolor: "action.hover",
                },
                py: 2,
              }}
            >
              <ListItemIcon>{getFileIcon(doc.type)}</ListItemIcon>

              <ListItemText
                primary={
                  <Box sx={{ display: "flex", alignItems: "center", gap: 1 }}>
                    {doc.name}
                  </Box>
                }
                secondary={
                  <Box sx={{ mt: 0.5 }}>
                    <Typography
                      variant="caption"
                      color="text.secondary"
                      component="span"
                    >
                      {`${doc.size} • Last modified: ${formatDateTime(
                        doc.updated_date
                      )} • `}
                    </Typography>
                    <Chip
                      label={doc.category || "general"}
                      size="small"
                      variant="outlined"
                      sx={{ ml: 1 }}
                    />
                  </Box>
                }
              />
              <IconButton onClick={deleteAttachment}>
                <Cancel />
              </IconButton>
            </ListItem>
          </React.Fragment>
        ))}
      </List>
    </Box>
  );
};
