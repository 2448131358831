import { ArrowForward, Info } from "@mui/icons-material";
import {
  Box,
  Dialog,
  DialogActions,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  styled,
  Tooltip,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";

// Styled components
const CarouselContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  gap: "20px",
  padding: "20px",
  position: "relative",
  overflowX: "hidden",
});

const CarouselTrack = styled(Box)({
  display: "flex",
  gap: "20px",
  transition: "transform 0.5s ease-in-out",
});

const CarouselItem = styled(Paper)({
  width: "220px",
  height: "220px",
  flexShrink: 0,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

const NavigationButton = styled(IconButton)({
  position: "absolute",
  right: 0,
  top: "50%",
  transform: "translateY(-50%)",
  backgroundColor: "#FFDB58",
  "&:hover": {
    backgroundColor: "#FFDB58",
  },
});

export default ({
  open,
  onClose,
  aiSuggestion,
  addToCanvas,
}: {
  open: boolean;
  onClose: () => void;
  aiSuggestion: any;
  addToCanvas: (productId: string) => void;
}) => {
  const [selectedSuggestion, setSelectedSuggestion] = useState<any>({});
  const [displayItems, setDisplayItems] = useState<any[]>([]);
  const [currentIndex, setCurrentIndex] = useState(0);

  const handleNext = () => {
    setCurrentIndex((prev) => prev + 1);

    // When we've scrolled through one full set, add another set to the end
    if (currentIndex % aiSuggestion.suggestedProducts.length === 0) {
      setDisplayItems((prev) => [...prev, ...aiSuggestion.suggestedProducts]);
    }
  };

  const BlurredBackdrop = styled("div")({
    backdropFilter: "blur(8px)",
    backgroundColor: "rgba(0, 0, 0, 0.5)",
    position: "fixed",
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  });

  const Circle = styled(Box)({
    borderRadius: "50%",
    width: 180,
    height: 180,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: "0.875rem",
    backgroundColor: "#1976d2", // Adjust colors as needed
    position: "relative",
  });

  const SmallCircle = styled(Box)({
    borderRadius: "50%",
    width: 70,
    height: 70,
    backgroundColor: "#4caf50",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    bottom: 20,
    right: 20,
    transform: "translate(50%, 50%)",
    fontSize: "0.75rem",
  });

  const suggestionClicked = (suggestion: any) => {
    setSelectedSuggestion(suggestion);
  };

  useEffect(() => {
    if (aiSuggestion.suggestedProducts.length > 0) {
      setSelectedSuggestion(aiSuggestion.suggestedProducts[0]);
      setDisplayItems([
        ...aiSuggestion.suggestedProducts,
        ...aiSuggestion.suggestedProducts,
        ...aiSuggestion.suggestedProducts,
      ]);
    }
  }, [aiSuggestion]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth
      maxWidth="md"
      sx={{ p: 5, backdropFilter: "blur(4px)" }}
    >
      <DialogContent>
        <CarouselContainer>
          <CarouselTrack
            sx={{
              transform: `translateX(-${currentIndex * (300 + 20)}px)`,
              transition:
                currentIndex === 0 ? "none" : "transform 0.5s ease-in-out",
            }}
          >
            {displayItems.map((suggestion, index) => (
              <CarouselItem
                key={index}
                elevation={3}
                onClick={() => suggestionClicked(suggestion)}
                sx={{
                  cursor: "pointer",
                  filter:
                    suggestion.name === selectedSuggestion.name
                      ? "brightness(100%)"
                      : "brightness(70%)",
                }}
              >
                <Circle>
                  {suggestion.product
                    ? suggestion.product.name
                    : suggestion.name}
                  <SmallCircle
                    sx={{
                      display:
                        suggestion.product && suggestion.product.discount
                          ? "flex"
                          : "none",
                    }}
                  >
                    <Typography variant="caption">
                      {suggestion.product &&
                        suggestion.product.discount &&
                        suggestion.product.discount.offer}
                    </Typography>
                    <Typography variant="caption">
                      {suggestion.product &&
                        suggestion.product.discount &&
                        suggestion.product.discount.offerEnds}
                    </Typography>
                  </SmallCircle>
                </Circle>
              </CarouselItem>
            ))}
          </CarouselTrack>
          <NavigationButton onClick={handleNext}>
            <ArrowForward />
          </NavigationButton>
        </CarouselContainer>
        <Box>
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <Typography variant="caption">Name</Typography>
              <Typography variant="h6">{selectedSuggestion.name}</Typography>
            </Grid>
            <Grid item xs={6}></Grid>
            <Grid item xs={3}>
              <Box style={{ display: "flex", alignItems: "center" }}>
                <Box>
                  <Typography variant="caption">Congruent Score</Typography>
                  <Typography variant="h6" sx={{ mt: 0 }}>
                    {selectedSuggestion.congruentScore}%
                  </Typography>
                </Box>
                <Tooltip
                  title="Congruent score represents how well a software will fit into your canvas landscape"
                  placement="right"
                >
                  <IconButton>
                    <Info />
                  </IconButton>
                </Tooltip>
              </Box>
            </Grid>
          </Grid>
          <Box sx={{ height: 200, minHeight: 200, overflow: "scroll" }}>
            <Box sx={{ mt: 3 }}>
              <Typography variant="caption">Description</Typography>
              <Typography variant="body1" sx={{ mt: 0.5 }}>
                {selectedSuggestion.reasoning}
              </Typography>
            </Box>
            <Box sx={{ mt: 3 }}>
              <Typography variant="caption">Gap Filled</Typography>
              <Typography variant="body1" sx={{ mt: 0.5 }}>
                {selectedSuggestion.productGaps}
              </Typography>
            </Box>
          </Box>
        </Box>
      </DialogContent>
      <DialogActions sx={{ p: 3 }}>
        <button
          style={{
            backgroundColor: "#FFDB58",
            border: "none",
            padding: "15px 20px",
            borderRadius: 5,
            cursor: "pointer",
          }}
          onClick={() => addToCanvas(selectedSuggestion.product.id)}
        >
          Add to Canvas
        </button>
      </DialogActions>
    </Dialog>
  );
};
