import { Button, CircularProgress } from "@mui/material";
import React from "react";

export default ({
  children,
  loading = false,
  variant = "text",
  onClick,
  ...props
}: any) => {
  return (
    <Button
      variant={variant}
      onClick={onClick}
      startIcon={loading ? <CircularProgress size={20} /> : <></>}
      disabled={loading}
      {...props}
    >
      {children}
    </Button>
  );
};
