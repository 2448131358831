import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material";
import {
  Elements,
  PaymentElement,
  useElements,
  useStripe,
} from "@stripe/react-stripe-js";
import { loadStripe } from "@stripe/stripe-js";
import * as React from "react";
import BackendApi from "../BackendApi";

const stripePromise = loadStripe(
  "pk_test_51Lfn9NIRkQwYWzpxDKrfywI0XGkxApnbBoODcQhguPOFtNzV2XmpXStEQsO4SDly39GPIeszqtjVW18CrS3SxHfU00lTH5D3Zx"
);

export function CheckoutDialog({
  open,
  onClose,
  message,
  backendApi,
}: {
  open: boolean;
  onClose: () => void;
  message: any;
  backendApi: BackendApi;
}) {
  const stripe = useStripe();
  const elements = useElements();
  const [errorMessage, setErrorMessage] = React.useState(null);
  const [amount, setAmount] = React.useState(300);
  if (!message) return <></>;

  const handleSubmit = async (event) => {
    event.preventDefault();

    if (elements == null) {
      return;
    }

    // Trigger form validation and wallet collection
    const { error: submitError } = await elements.submit();
    if (submitError) {
      // Show error to your customer
      setErrorMessage(submitError.message);
      return;
    }

    const { error, confirmationToken } = await stripe.createConfirmationToken({
      elements,
      params: {},
    });

    // Create the PaymentIntent and obtain clientSecret from your server endpoint
    const { clientSecret, status } = await backendApi.createStripeIntent(
      message.id,
      amount,
      confirmationToken
    );
    console.log(clientSecret, status);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Typography id="modal-modal-title" variant="h6" component="h2">
          Checkout
        </Typography>
        <Typography variant="body2" color="textSecondary" component="p">
          Review your purchase details below.
        </Typography>
        <form onSubmit={handleSubmit}>
          <Box mt={2}>
            <Typography variant="subtitle2">Purchase Details:</Typography>
            <Typography variant="body2">Name: {message.user.name}</Typography>
            <Typography variant="body2">Email: {message.user.email}</Typography>
            <Typography variant="body2">Phone: {message.user.phone}</Typography>
            <Typography variant="body2">
              Congruent Canvas: {message.user.canvasLink}
            </Typography>
            <Typography variant="body2">
              Product Interest: {message.product.name}
            </Typography>
            <Typography variant="body2">
              Industry: {message.organization.industry}
            </Typography>
            <Typography variant="body2">
              Company Size: {message.organization.company_size}
            </Typography>
            <Typography variant="body2">
              Location: {message.organization.location}
            </Typography>
            <Typography variant="subtitle1" mt={2}>
              Total Charge: $3.75
            </Typography>
            <PaymentElement />
          </Box>
          <Box mt={2} display="flex" justifyContent="flex-end">
            <Button
              variant="contained"
              color="primary"
              type="submit"
              disabled={!stripe || !elements}
            >
              Confirm Purchase
            </Button>
          </Box>
        </form>
      </DialogContent>
    </Dialog>
  );
}

export default (props) => {
  const options: any = {
    mode: "payment",
    amount: 1099,
    currency: "usd",
    // Fully customizable with appearance API.
    appearance: {
      /*...*/
    },
  };

  return (
    <Elements stripe={stripePromise} options={options}>
      <CheckoutDialog {...props} />
    </Elements>
  );
};
