import {
  Box,
  Button,
  ButtonGroup,
  Link,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { Session, SupabaseClient } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import BackendApi from "../BackendApi";
import CompanyChatDialog from "./CompanyChatDialog";
import PurchaseLeadDialog from "./PurchaseLeadDialog";

export default ({
  backendApi,
  session,
  supabase,
}: {
  backendApi: BackendApi;
  session: Session;
  supabase: SupabaseClient;
}) => {
  const [messages, setMessages] = useState([]);
  const [purchaseOpen, setPurchaseOpen] = useState(false);
  const [chatOpen, setChatOpen] = useState(false);
  const [selectedMessage, setSelectedMessage] = useState<any>(null);
  const [selectedConversation, setSelectedConversation] = useState<any>(null);

  const onClickMessage = (message: any) => {
    if (!message.purchased) {
      setPurchaseOpen(true);
      setSelectedMessage(message);
    } else {
      setSelectedMessage(message);
      setSelectedConversation(message.conversation);
      setChatOpen(true);
    }
  };

  const getMessages = async () => {
    const messages = await backendApi.getCompanyMessages();
    setMessages(messages);
  };

  useEffect(() => {
    getMessages();
  }, []);

  return (
    <Box>
      <ButtonGroup
        variant="contained"
        aria-label="outlined primary button group"
        sx={{ mb: 2 }}
      >
        <Button>All</Button>
        <Button>New</Button>
        <Button>Purchased</Button>
      </ButtonGroup>
      <TableContainer component={Paper}>
        <Table aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Name</TableCell>
              <TableCell>Email</TableCell>
              <TableCell>Phone</TableCell>
              <TableCell>Canvas Link</TableCell>
              <TableCell>Product Interest</TableCell>
              <TableCell>Industry</TableCell>
              <TableCell>CompanySize</TableCell>
              <TableCell>Location</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {messages.map((row, index) => (
              <TableRow key={index}>
                <TableCell
                  sx={{ filter: !row.purchased ? "blur(10px)" : "none" }}
                >
                  {row.user.name}
                </TableCell>
                <TableCell
                  sx={{ filter: !row.purchased ? "blur(10px)" : "none" }}
                >
                  {row.user.email}
                </TableCell>
                <TableCell
                  sx={{ filter: !row.purchased ? "blur(10px)" : "none" }}
                >
                  {row.user.phone}
                </TableCell>
                <TableCell
                  sx={{ filter: !row.purchased ? "blur(10px)" : "none" }}
                >
                  <Link href={row.user.canvasLink}>Canvas Link</Link>
                </TableCell>
                <TableCell>{row.product.name}</TableCell>
                <TableCell>{row.organization.industry}</TableCell>
                <TableCell>{row.organization.company_size}</TableCell>
                <TableCell>{row.organization.location}</TableCell>
                <TableCell>
                  {(row.purchased && (
                    <Button
                      variant="contained"
                      color="success"
                      onClick={() => onClickMessage(row)}
                    >
                      Chat
                    </Button>
                  )) || (
                    <Button
                      variant="contained"
                      color="warning"
                      onClick={() => onClickMessage(row)}
                    >
                      Buy
                    </Button>
                  )}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
      <PurchaseLeadDialog
        open={purchaseOpen}
        onClose={() => {
          setSelectedMessage(null);
          setPurchaseOpen(false);
          getMessages();
        }}
        message={selectedMessage}
        backendApi={backendApi}
      />
      <CompanyChatDialog
        open={chatOpen}
        onClose={() => setChatOpen(false)}
        backendApi={backendApi}
        session={session}
        supabase={supabase}
        selectedConversation={selectedConversation}
        companyId={selectedMessage && selectedMessage.company_id}
      />
    </Box>
  );
};
