import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography,
} from "@mui/material";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

export default ({ onClose, product, backendApi, canvasId, canvasNodeId }) => {
  const [existingCustomer, setExistingCustomer] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [file, setFile] = useState<any>(null);
  const navigate = useNavigate();

  const uploadAttachment = async () => {
    setUploading(true);
    await backendApi.saveAttachment(canvasId, canvasNodeId, file);
    setUploading(false);
    onClose();
  };

  const contactUs = async () => {
    console.log(product);
    const res = await backendApi.createChat(product);
    onClose()
    navigate("/admin/messages");
  };

  const updateFile = (e: any) => {
    setFile(e.target.files[0]);
  };

  if (!product) {
    return <></>;
  }
  return (
    <Dialog open={product !== null} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogTitle>
        <Box display="flex" justifyContent="center" alignItems="center">
          <Box
            component="img"
            src={product?.logo_url}
            alt={product?.name}
            height={50}
            width={50}
          />
        </Box>
      </DialogTitle>
      <DialogContent dividers>
        <Box display="flex" flexDirection="column">
          <FormControlLabel
            value={existingCustomer}
            onChange={() => setExistingCustomer(!existingCustomer)}
            control={<Switch color="primary" />}
            label={`I already use ${product?.name}`}
          />
          {existingCustomer && (
            <Box sx={{ mt: 2 }}>
              <Typography variant="h6" gutterBottom>
                Welcome back, valued {product?.name} customer!
              </Typography>
              <Typography variant="body1" paragraph>
                As an existing customer, you can upload your current{" "}
                {product?.name}
                contract for our records and to provide better congruent
                suggestions.
              </Typography>
              <Typography variant="subtitle1">
                Select {product?.name} contract file
              </Typography>
              <Button
                variant="outlined"
                startIcon={uploading ? <CircularProgress size={20} /> : <></>}
                disabled={uploading}
                component="label"
              >
                Choose File
                <input
                  id="file-upload"
                  type="file"
                  onChange={updateFile}
                  hidden
                />
              </Button>
              <Typography variant="subtitle1">
                {file ? file.name : "No file chosen"}
              </Typography>
              <Box sx={{ mt: 4, width: "100%" }}>
                <Button
                  onClick={uploadAttachment}
                  color="primary"
                  variant="contained"
                >
                  Upload {product.name} Contract
                </Button>
              </Box>
            </Box>
          )}
          {!existingCustomer && (
            <Box
              display="flex"
              justifyContent="center"
              flexDirection="column"
              alignItems="center"
              sx={{ mt: 2 }}
            >
              <Typography>
                Interested in {product?.name}? Get in touch with us and receive
                10% off your deal!
              </Typography>
              <Button
                sx={{ mt: 2 }}
                onClick={contactUs}
                variant="contained"
              >
                Contact Us
              </Button>
            </Box>
          )}
        </Box>
      </DialogContent>
    </Dialog>
  );
};
