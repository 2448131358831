import { Box, Card, CardContent } from "@mui/material";
import { Auth } from "@supabase/auth-ui-react";
import { ThemeSupa } from "@supabase/auth-ui-shared";
import { SupabaseClient } from "@supabase/supabase-js";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Logo from "./Logo";
import { hostname } from "./utils";

export default function Login({
  supabase,
  mode,
}: {
  supabase: SupabaseClient;
  mode: string;
}) {
  const [session, setSession] = useState(null);
  const navigate = useNavigate();

  useEffect(() => {
    supabase.auth.getSession().then(({ data: { session } }) => {
      setSession(session);
      if (session && session.user) {
        navigate("/admin");
      }
    });

    const {
      data: { subscription },
    } = supabase.auth.onAuthStateChange((_event, session) => {
      setSession(session);
      if (session && session.user) {
        navigate("/admin");
      }
    });
    return () => subscription.unsubscribe();
  }, []);

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100vh",
        width: "100vw",
      }}
    >
      <Card sx={{ width: 425, p: 3, mx: "auto" }}>
        <CardContent>
          <Box sx={{ pt: 3, pb: 3, textAlign: "center" }}>
            <Logo mode={mode} />
          </Box>
          <Auth
            redirectTo={`${hostname}/admin`}
            supabaseClient={supabase}
            appearance={{ theme: ThemeSupa }}
            providers={["google"]}
          />
        </CardContent>
      </Card>
    </Box>
  );
}
