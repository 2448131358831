import {
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardMedia,
  Grid,
  TextField,
  Typography,
} from "@mui/material";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { API_HOSTNAME } from "../constants";

const consultantsDefault = [
  {
    id: 5,
    name: "Lucy Heart",
    picture: "https://i.pravatar.cc/300?img=5",
    keywords: "Healthcare IT, Compliance",
    rating: 3.9,
  },
  {
    id: 6,
    name: "Gary Young",
    picture: "https://i.pravatar.cc/300?img=6",
    keywords: "Cybersecurity, Network Defense",
    rating: 4.6,
  },
  {
    id: 7,
    name: "Sam Bloom",
    picture: "https://i.pravatar.cc/300?img=7",
    keywords: "Data Analytics, BI Tools",
    rating: 4.0,
  },
  {
    id: 8,
    name: "Chris Rock",
    picture: "https://i.pravatar.cc/300?img=8",
    keywords: "SaaS Products, Market Strategy",
    rating: 4.5,
  },
  {
    id: 9,
    name: "Bella Swan",
    picture: "https://i.pravatar.cc/300?img=9",
    keywords: "E-commerce, B2C Models",
    rating: 4.8,
  },
  {
    id: 10,
    name: "Harry White",
    picture: "https://i.pravatar.cc/300?img=10",
    keywords: "Machine Learning, AI",
    rating: 4.2,
  },
  {
    id: 11,
    name: "Tom Redding",
    picture: "https://i.pravatar.cc/300?img=11",
    keywords: "Fintech, Blockchain",
    rating: 4.9,
  },
  {
    id: 12,
    name: "Sara Parker",
    picture: "https://i.pravatar.cc/300?img=12",
    keywords: "UX/UI Design, Frontend Development",
    rating: 4.3,
  },
  {
    id: 13,
    name: "Robert Fox",
    picture: "https://i.pravatar.cc/300?img=13",
    keywords: "Mobile App Development, React Native",
    rating: 4.6,
  },
  {
    id: 14,
    name: "Olivia Kane",
    picture: "https://i.pravatar.cc/300?img=14",
    keywords: "Social Media Marketing, SEO",
    rating: 4.7,
  },
  {
    id: 15,
    name: "Mark Neal",
    picture: "https://i.pravatar.cc/300?img=15",
    keywords: "Digital Advertising, Google Ads",
    rating: 4.5,
  },
  {
    id: 16,
    name: "Nina Morris",
    picture: "https://i.pravatar.cc/300?img=16",
    keywords: "Real Estate Technologies, GIS",
    rating: 4.4,
  },
  {
    id: 17,
    name: "Dave Wilson",
    picture: "https://i.pravatar.cc/300?img=17",
    keywords: "E-learning, Educational Technologies",
    rating: 4.1,
  },
  {
    id: 18,
    name: "Ella Fitzgerald",
    picture: "https://i.pravatar.cc/300?img=18",
    keywords: "Music Technologies, Production Software",
    rating: 4.8,
  },
  {
    id: 19,
    name: "Sophie Turner",
    picture: "https://i.pravatar.cc/300?img=19",
    keywords: "Entertainment Industry, Streaming Technologies",
    rating: 4.2,
  },
  {
    id: 20,
    name: "Liam Neeson",
    picture: "https://i.pravatar.cc/300?img=20",
    keywords: "Logistics, Supply Chain Management",
    rating: 4.6,
  },
  {
    id: 21,
    name: "Grace Lin",
    picture: "https://i.pravatar.cc/300?img=21",
    keywords: "Game Development, Unity & Unreal Engines",
    rating: 4.3,
  },
  {
    id: 22,
    name: "Frank Murphy",
    picture: "https://i.pravatar.cc/300?img=22",
    keywords: "Pharmaceuticals, R&D",
    rating: 4.7,
  },
  {
    id: 23,
    name: "Emma Stone",
    picture: "https://i.pravatar.cc/300?img=23",
    keywords: "Legal Tech, Compliance Software",
    rating: 4.5,
  },
  {
    id: 24,
    name: "Ryan Gosling",
    picture: "https://i.pravatar.cc/300?img=24",
    keywords: "Automotive Industry, Manufacturing Technologies",
    rating: 4.6,
  },
  {
    id: 25,
    name: "Natalie Portman",
    picture: "https://i.pravatar.cc/300?img=25",
    keywords: "Healthcare Management, Hospital Software",
    rating: 4.8,
  },
];

export default ({ session }: any) => {
  const [searchTerm, setSearchTerm] = useState("");
  const [consultants, setConsultants] = useState(consultantsDefault);

  const handleSearchChange = (event) => {
    setSearchTerm(event.target.value);
  };

  const filteredConsultants = consultants.filter(
    (consultant) =>
      consultant.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
      consultant.keywords.toLowerCase().includes(searchTerm.toLowerCase())
  );

  const getConsultants = () => {
    axios
      .get(`${API_HOSTNAME}/consultants`, {
        headers: { Authorization: `Bearer ${session.access_token}` },
      })
      .then((res) => {
        setConsultants([...res.data.consultants, ...consultantsDefault]);
      });
  };

  useEffect(() => {
    if (session && session.access_token) {
      getConsultants();
    }
  }, [session]);

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <TextField
        fullWidth
        label="Search Consultants"
        variant="outlined"
        value={searchTerm}
        onChange={handleSearchChange}
        sx={{ mb: 2 }}
      />
      <Grid container spacing={3}>
        {filteredConsultants.map((consultant) => (
          <Grid item key={consultant.id} xs={12} sm={6} md={4}>
            <Card>
              <CardActionArea>
                <CardMedia
                  component="img"
                  height="140"
                  image={consultant.picture}
                  alt={consultant.name}
                />
                <CardContent sx={{ height: 140 }}>
                  <Typography gutterBottom variant="h5" component="div">
                    {consultant.name}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Expertise: {consultant.keywords}
                  </Typography>
                  <Typography variant="body2" color="text.secondary">
                    Rating: {consultant.rating || "0"} / 5
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};
